<template>
  <div class="collection-modal-container">
    <h4 class="color3 text-center">ADD NEW COLLECTION</h4>
    <label class="mt-3">
      Collection Name
    </label>
    <b-form-input v-model="newCollection.title"></b-form-input>

    <div class="justify-end mt-4 mb-3">
      <my-button
        type="outline-blue"
        size="lg"
        class="mr-2"
        @click="$emit('cancel')"
      >
        CANCEL
      </my-button>

      <my-button type="blue" size="lg" @click="handleSave">
        SAVE
      </my-button>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    newCollection: {
      title: "Lesson2",
      chapters: []
    }
  }),
  methods: {
    handleSave() {
      this.$emit("save", this.newCollection);
    }
  }
};
</script>

<style>
.collection-modal-container {
  padding: 20px 44px;
}

@media screen and (max-width: 768px) {
  .collection-modal-container {
    padding: 20px 0;
  }
}
</style>
