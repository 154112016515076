<template>
  <div class="instructor-modal-container">
    <h4 class="color3 text-center">ADD NEW ISTRUCTOR</h4>
    <label class="mt-3">
      Nama Lengkap Instructor
    </label>
    <b-form-input value="Fitriani Sanusi"></b-form-input>

    <label class="mt-4-5">Profile Photo</label><br />
    <div class="upload-image">
      <div class="upload-image__item">
        <div class="upload-image__top">
          <img src="/img/thumbnail.png" alt="img" />
        </div>

        <div class="upload-image__bottom">
          <input
            type="file"
            name="profilephoto"
            id="profilephoto"
            class="hidden-input"
          />
          <label for="profilephoto" class="file-upload">
            <fa-icon icon="upload" class="mr-1" />
            Upload Image
          </label>
        </div>
      </div>
    </div>

    <label class="mt-4-5">Profile</label><br />
    <vue-editor v-model="newInstructor.profile"></vue-editor>

    <label class="mt-4-5">
      Twitter
    </label>
    <b-form-input placeholder="Type Here..."></b-form-input>

    <label class="mt-4-5">
      Instagram
    </label>
    <b-form-input placeholder="Type Here..."></b-form-input>

    <label class="mt-4-5">
      Facebook
    </label>
    <b-form-input placeholder="Type Here..."></b-form-input>

    <label class="mt-4-5">
      LinkedIn
    </label>
    <b-form-input placeholder="Type Here..."></b-form-input>

    <label class="mt-4-5">
      Youtube
    </label>
    <b-form-input placeholder="Type Here..."></b-form-input>

    <div class="justify-end mt-4 mb-3">
      <my-button
        type="outline-blue"
        size="lg"
        class="mr-2"
        @click="$emit('cancel')"
      >
        CANCEL
      </my-button>

      <my-button type="blue" size="lg" @click="handleSave">
        SAVE
      </my-button>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    newInstructor: {
      profile:
        "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit"
    }
  }),
  methods: {
    handleSave() {
      this.$emit("save", this.newInstructor);
    }
  }
};
</script>

<style>
.instructor-modal-container {
  padding: 20px 44px;
}

@media screen and (max-width: 768px) {
  .instructor-modal-container {
    padding: 20px 0;
  }
}
</style>
