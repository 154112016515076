<template>
  <div>
    <div class="form-container">
      <b-row>
        <b-col md="auto">
          <label>
            Category
          </label>
        </b-col>

        <b-col md="auto">
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="newProduct.category"
            :options="categories"
            name="flavour-1"
          ></b-form-checkbox-group>
        </b-col>
      </b-row>

      <b-form-input
        placeholder="Type"
        value="Type: E-Course"
        disabled
        class="mt-4 w-auto"
      ></b-form-input>

      <label class="mt-4-5">Judul E-Course</label>
      <b-form-input placeholder="Title..."></b-form-input>

      <label class="mt-4-5">
        Overview
      </label>
      <vue-editor v-model="newProduct.overview"></vue-editor>

      <b-row class="mt-4-5">
        <b-col md="auto">
          <label>Featured Image</label><br />
          <div class="upload-image">
            <div class="upload-image__item">
              <div class="upload-image__top">
                <img src="/img/thumbnail.png" alt="img" />
              </div>

              <div class="upload-image__bottom">
                <input
                  type="file"
                  name="file1"
                  id="file1"
                  class="hidden-input"
                />
                <label for="file1" class="file-upload">
                  <fa-icon icon="upload" class="my-icon" />
                  Upload Image
                </label>
              </div>
            </div>
          </div>
        </b-col>

        <b-col md="auto">
          <label>Video Preview</label><br />
          <div class="upload-image">
            <div class="upload-image__item">
              <div class="upload-image__top">
                <div class="video-preview">
                  <img src="/img/video-preview.png" alt="img" />
                </div>
              </div>

              <div class="upload-image__bottom">
                <input
                  type="file"
                  name="file2"
                  id="file2"
                  class="hidden-input"
                />
                <label class="file-upload file-upload--remove mr-3">
                  <fa-icon icon="times" class="mr-1" />
                  Remove Video
                </label>

                <label for="file2" class="file-upload">
                  <fa-icon icon="upload" class="mr-1" />
                  Upload Video
                </label>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>

      <label class="mt-4">Product Gallery</label><br />
      <div class="upload-image">
        <div class="upload-image__item mr-4">
          <div class="upload-image__top">
            <img src="/img/nopath-3.png" alt="img" />
            <div class="img-remove">
              X Remove
            </div>
          </div>

          <div class="upload-image__bottom">
            <input type="file" name="file1" id="file1" class="hidden-input" />
            <label for="file1" class="file-upload">
              <fa-icon icon="upload" class="my-icon" />
              Upload Image
            </label>
          </div>
        </div>

        <div class="upload-image__item mr-4">
          <div class="upload-image__top">
            <img src="/img/nopath-4.png" alt="img" />
            <div class="img-remove">
              X Remove
            </div>
          </div>

          <div class="upload-image__bottom">
            <input type="file" name="file1" id="file1" class="hidden-input" />
            <label for="file1" class="file-upload">
              <fa-icon icon="upload" class="my-icon" />
              Upload Image
            </label>
          </div>
        </div>

        <div class="upload-image__item mr-4">
          <div class="upload-image__top">
            <img src="/img/nopath-6.png" alt="img" />
            <div class="img-remove">
              X Remove
            </div>
          </div>

          <div class="upload-image__bottom">
            <input type="file" name="file1" id="file1" class="hidden-input" />
            <label for="file1" class="file-upload">
              <fa-icon icon="upload" class="my-icon" />
              Upload Image
            </label>
          </div>
        </div>

        <div class="upload-image__item mr-4">
          <div class="upload-image__top">
            <img src="/img/thumbnail.png" alt="img" />
          </div>

          <div class="upload-image__bottom">
            <input type="file" name="file1" id="file1" class="hidden-input" />
            <label for="file1" class="file-upload">
              <fa-icon icon="upload" class="my-icon" />
              Upload Image
            </label>
          </div>
        </div>

        <div class="upload-image__item c-pointer">
          <img src="/img/add-image.png" alt="" />
        </div>
      </div>

      <label class="mt-4">File PDF</label><br />

      <div class="course-collection">
        <div class="space-between align-start">
          <div class="flex-1">
            <div class="align-start">
              <img src="/img/icons/pdf-icon.svg" alt="" class="mr-2" />
              <p class="flex-1 mr-2">
                Membuat Landing Page Menggunakan Tools Gratis buatan Developer
                Indonesia
              </p>
            </div>
          </div>

          <div class="align-center">
            <my-button
              size="sm"
              type="outline"
              class="mr-3"
              @click="contentModal = true"
            >
              <fa-icon icon="search" class="mr-1" /> Search
            </my-button>
            <b-button
              size="sm"
              class="mr-2 action-button"
              variant="outline-secondary"
            >
              <fa-icon icon="pencil-alt" />
            </b-button>
            <b-button
              size="sm"
              class="mr-2 action-button"
              variant="outline-secondary"
            >
              <fa-icon icon="trash-alt" />
            </b-button>
          </div>
        </div>

        <hr />

        <div class="space-between align-start">
          <div class="flex-1">
            <div class="align-start">
              <img src="/img/icons/pdf-icon.svg" alt="" class="mr-2" />
              <p class="flex-1 mr-2 mb-0">
                Membuat Landing Page Menggunakan Tools Gratis buatan Developer
                Indonesia
              </p>
            </div>
          </div>

          <div class="align-center">
            <my-button
              size="sm"
              type="outline"
              class="mr-3"
              @click="contentModal = true"
            >
              <fa-icon icon="search" class="mr-1" /> Search
            </my-button>
            <b-button
              size="sm"
              class="mr-2 action-button"
              variant="outline-secondary"
            >
              <fa-icon icon="pencil-alt" />
            </b-button>
            <b-button
              size="sm"
              class="mr-2 action-button"
              variant="outline-secondary"
            >
              <fa-icon icon="trash-alt" />
            </b-button>
          </div>
        </div>
      </div>

      <my-button type="orange" size="sm" rounded class="mt-2">
        <fa-icon icon="plus-circle" class="mr-1"></fa-icon>
        ADD NEW FILE
      </my-button>

      <br />

      <label class="mt-4-5">Harga</label>
      <b-form-input placeholder="Type here..."></b-form-input>

      <div class="align-center mt-4-5">
        <label class="mr-4">Harga Diskon</label>
        <div class="discount-percent discount-percent--active mr-2">
          10%
        </div>

        <div class="discount-percent mr-2">
          15%
        </div>

        <div class="discount-percent">
          25%
        </div>
      </div>
      <b-form-input placeholder="Type here..."></b-form-input>

      <label class="mt-4-5">Berat</label>

      <b-input-group append="kg">
        <b-form-input placeholder="Type here..."></b-form-input>
      </b-input-group>
    </div>
    <hr class="mt-5" />

    <div class="form-container--lg">
      <b-row class="mt-5">
        <b-col md="3">
          <div
            class="form-tab"
            :class="{ 'form-tab--active': contentTab === 'Instructors' }"
            @click="contentTab = 'Instructors'"
          >
            Instructors
          </div>

          <div
            class="form-tab"
            :class="{ 'form-tab--active': contentTab === 'Course Content' }"
            @click="contentTab = 'Course Content'"
          >
            Course Content
          </div>
        </b-col>

        <b-col md="9">
          <div v-if="contentTab === 'Instructors'">
            <div class="space-between align-start">
              <div class="align-start">
                <img src="/img/avatars/avatar1.png" alt="" class="mr-3 mb-3" />
                <div>
                  <h4>Bryan Furran</h4>
                  <p class="lite">
                    SPV Gojek Regional South East Asia
                  </p>
                </div>
              </div>

              <div class="align-center">
                <div
                  class="instructor-social"
                  v-b-tooltip.hover.bottom
                  title="Linked In"
                >
                  <fa-icon :icon="['fab', 'linkedin-in']"></fa-icon>
                </div>

                <div
                  class="instructor-social"
                  v-b-tooltip.hover.bottom
                  title="Facebook"
                >
                  <fa-icon :icon="['fab', 'facebook']"></fa-icon>
                </div>

                <div
                  class="instructor-social"
                  v-b-tooltip.hover.bottom
                  title="Twitter"
                >
                  <fa-icon :icon="['fab', 'twitter']"></fa-icon>
                </div>

                <div
                  class="instructor-social"
                  v-b-tooltip.hover.bottom
                  title="Youtube"
                >
                  <fa-icon :icon="['fab', 'youtube']"></fa-icon>
                </div>

                <div
                  class="instructor-social"
                  v-b-tooltip.hover.bottom
                  title="Instagram"
                >
                  <fa-icon :icon="['fab', 'instagram']"></fa-icon>
                </div>
              </div>
            </div>

            <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
              nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam
              erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci
              tation ullamcorper suscipit
            </p>

            <p class="text-right">
              <my-button type="outline" size="sm" class="mr-2">
                <fa-icon icon="pencil-alt" class="mr-1"></fa-icon>
                Edit
              </my-button>

              <my-button type="outline" size="sm">
                <fa-icon icon="trash-alt" class="mr-1"></fa-icon>
                Delete
              </my-button>
            </p>

            <my-button
              type="orange"
              size="sm"
              class="mt-4"
              rounded
              @click="instructorModal = true"
            >
              <fa-icon icon="plus-circle" class="mr-1"></fa-icon>
              ADD NEW ISTRUCTOR
            </my-button>
          </div>

          <div v-if="contentTab === 'Course Content'">
            <div
              class="course-collection mb-4"
              v-for="(cc, i) in courseContents"
              :key="i"
            >
              <div class="space-between align-center">
                <div class="flex-1">
                  <h4>{{ cc.title }}</h4>
                </div>

                <div class="align-center">
                  <b-button
                    size="sm"
                    class="mr-2 action-button"
                    variant="outline-secondary"
                  >
                    <fa-icon icon="pencil-alt" class="mr-1" />Edit
                  </b-button>
                  <b-button
                    size="sm"
                    class="mr-2 action-button"
                    variant="outline-secondary"
                  >
                    <fa-icon icon="trash-alt" class="mr-1" /> Delete
                  </b-button>

                  <my-button
                    size="sm"
                    type="orange"
                    class="mr-3 mt-2 mt-md-0"
                    @click="contentModal = true"
                  >
                    <fa-icon icon="plus" class="mr-1" /> Add Content
                  </my-button>
                </div>
              </div>

              <hr />

              <p class="lite" v-if="cc.chapters.length === 0">Belum ada isi</p>

              <div
                class="space-between align-center mb-2"
                v-for="(chapter, j) in cc.chapters"
                :key="j"
              >
                <div class="flex-1 align-center">
                  <img src="/img/icons/video.svg" alt="" class="mr-2" />
                  <span>
                    {{ chapter.name }}
                  </span>
                  <div class="course-preview">
                    Preview
                  </div>
                </div>

                <div class="align-center">
                  <p class="lite small mb-0 mr-2">
                    {{ chapter.duration }}
                  </p>

                  <b-button
                    size="sm"
                    class="mr-2 action-button"
                    variant="outline-secondary"
                  >
                    <fa-icon icon="pencil-alt" />
                  </b-button>
                  <b-button
                    size="sm"
                    class="mr-2 action-button"
                    variant="outline-secondary"
                  >
                    <fa-icon icon="trash-alt" />
                  </b-button>
                </div>
              </div>
            </div>

            <my-button type="orange" size="sm" @click="collectionModal = true">
              <fa-icon icon="plus-circle" class="mr-1"></fa-icon>
              ADD NEW COLLECTION
            </my-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <hr class="mt-5" />

    <div class="form-container--lg">
      <h4 class="color3 mt-5">
        AFFILIASI
      </h4>

      <b-row class="mt-4">
        <b-col md="3">
          <div
            class="form-tab"
            :class="{ 'form-tab--active': affiliasiTab === 'Komisi' }"
            @click="affiliasiTab = 'Komisi'"
          >
            Komisi
          </div>

          <div
            class="form-tab"
            :class="{ 'form-tab--active': contentTab === 'Alat Promosi' }"
            @click="affiliasiTab = 'Alat Promosi'"
          >
            Alat Promosi
          </div>
        </b-col>

        <b-col md="9">
          <div v-if="affiliasiTab === 'Komisi'">
            <label>Tipe Komisi</label> <br />
            <b-form-select
              :options="tipeKomisis"
              v-model="newProduct.tipeKomisi"
              class="w-auto"
            ></b-form-select>
            <br />

            <label class="mt-4-5">Besar Komisi (Persentase %)</label>
            <b-input-group append="IDR50.000">
              <b-form-input placeholder="0%"></b-form-input>
            </b-input-group>

            <label class="mt-4-5">Besar Komisi (IDR)</label>
            <b-form-input value="IDR50.000"></b-form-input>

            <div class="form-collapse mt-4-5">
              <div
                class="form-collapse__top"
                @click="newProduct.link1.expanded = !newProduct.link1.expanded"
              >
                <span>Link 1</span>
                <span
                  class="transition"
                  :class="{ 'rotate-180': newProduct.link1.expanded }"
                >
                  <fa-icon icon="sort-down"></fa-icon>
                </span>
              </div>

              <b-collapse v-model="newProduct.link1.expanded">
                <div class="form-collapse__bottom">
                  <div class="aspace-between align-start">
                    <div class="flex-1">
                      {{ newProduct.link1.script }}
                    </div>

                    <my-button type="orange" size="sm" rounded class="ml-2">
                      <fa-icon icon="copy" class="mr-1"></fa-icon>
                      Copy Script
                    </my-button>
                  </div>
                </div>
              </b-collapse>
            </div>

            <div class="form-collapse mt-4-5">
              <div
                class="form-collapse__top"
                @click="newProduct.link2.expanded = !newProduct.link2.expanded"
              >
                <span>Link 2</span>
                <span
                  class="transition"
                  :class="{ 'rotate-180': newProduct.link2.expanded }"
                >
                  <fa-icon icon="sort-down"></fa-icon>
                </span>
              </div>

              <b-collapse v-model="newProduct.link2.expanded">
                <div class="form-collapse__bottom">
                  <div class="aspace-between align-start">
                    <div class="flex-1">
                      {{ newProduct.link2.script }}
                    </div>

                    <my-button type="orange" size="sm" rounded class="ml-2">
                      <fa-icon icon="copy" class="mr-1"></fa-icon>
                      Copy Script
                    </my-button>
                  </div>
                </div>
              </b-collapse>
            </div>

            <div class="form-collapse mt-4-5">
              <div
                class="form-collapse__top"
                @click="
                  newProduct.banner.expanded = !newProduct.banner.expanded
                "
              >
                <span>Banner</span>
                <span
                  class="transition"
                  :class="{ 'rotate-180': newProduct.banner.expanded }"
                >
                  <fa-icon icon="sort-down"></fa-icon>
                </span>
              </div>

              <b-collapse v-model="newProduct.banner.expanded">
                <div
                  class="form-collapse__bottom form-collapse__bottom--no-padding"
                >
                  <div class="form-collapse__padding">
                    <label>
                      Ukuran
                    </label>
                    <p>
                      {{ newProduct.banner.ukuran }}
                    </p>

                    <label class="mt-3">
                      Link Afiliasi
                    </label>
                    <p>
                      {{ newProduct.banner.linkAfiliasi }}
                    </p>

                    <label class="mt-3">
                      Target URL
                    </label>
                    <p>
                      {{ newProduct.banner.targetURL }}
                    </p>

                    <label class="mt-3">
                      Harga
                    </label>
                    <p>
                      {{ newProduct.banner.harga }}
                    </p>

                    <label class="mt-3">
                      Kode HTML Link Afiliasi
                    </label>
                  </div>

                  <div class="form-collapse__gray">
                    <div class="aspace-between align-start">
                      <div class="flex-1">
                        {{ newProduct.banner.kodeHtml }}
                      </div>

                      <my-button type="orange" size="sm" rounded class="ml-2">
                        <fa-icon icon="copy" class="mr-1"></fa-icon>
                        Copy Script
                      </my-button>
                    </div>
                  </div>

                  <div class="form-collapse__padding mt-4 mb-3">
                    <label>Image Preview</label><br />
                    <img :src="newProduct.banner.imagePreview" />
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </b-col>
      </b-row>

      <my-button type="blue" size="lg" block class="mt-4-5" rounded>
        SIMPAN PRODUK
      </my-button>
    </div>

    <b-modal
      v-model="instructorModal"
      id="instructorModal"
      hide-header
      hide-footer
      centered
    >
      <modal-cross @close="instructorModal = false"></modal-cross>
      <instructor-modal
        @save="instructorModal = false"
        @cancel="instructorModal = false"
      ></instructor-modal>
    </b-modal>

    <b-modal
      v-model="collectionModal"
      id="collectionModal"
      hide-header
      hide-footer
      centered
    >
      <modal-cross @close="collectionModal = false"></modal-cross>
      <collection-modal
        @save="
          courseContents.push($event);
          collectionModal = false;
        "
        @cancel="collectionModal = false"
      ></collection-modal>
    </b-modal>

    <b-modal
      v-model="contentModal"
      id="contentModal"
      hide-header
      hide-footer
      centered
    >
      <modal-cross @close="contentModal = false"></modal-cross>
      <content-modal
        @save="
          /*courseContents.push($event);*/
          contentModal = false
        "
        @cancel="contentModal = false"
      ></content-modal>
    </b-modal>
  </div>
</template>

<script>
import InstructorModal from "./InstructorModal";
import CollectionModal from "./CollectionModal";
import ContentModal from "./ContentModal";
import { courseContents } from "../../../data/courseContents";

export default {
  data: () => ({
    courseContents: courseContents,
    instructorModal: false,
    collectionModal: false,
    contentModal: false,
    contentTab: "Instructors", //Instructors
    affiliasiTab: "Komisi",
    categories: [
      { text: "FB Ads", value: "FB Ads" },
      { text: "Web Development", value: "Web Development" },
      { text: "Instagram Ads", value: "Instagram Ads" }
    ],
    tipeKomisis: [
      {
        text: "Select One",
        value: ""
      },
      {
        text: "Choose Type",
        value: "Choose Type"
      },
      {
        text: "Presentase",
        value: "Presentase"
      },
      {
        text: "Flat",
        value: "Flat"
      }
    ],

    newProduct: {
      category: [],
      overview:
        "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit",
      tipeKomisi: "",
      link1: {
        expanded: false,
        script:
          "Your script here Your script here Your script here Your script here Your script here Your script here Your script here "
      },
      link2: {
        expanded: false,
        script:
          "Your script here Your script here Your script here Your script here Your script here Your script here "
      },
      banner: {
        expanded: false,
        ukuran: "125x125",
        linkAfiliasi:
          "https://account.nesiamarket.com/aff/go/ryanpradiptaputra?i=1073",
        targetURL:
          "https://account.nesiamarket.com/aff/go/ryanpradiptaputra?i=1073",
        harga: "Rp150.000",
        kodeHtml:
          "Your html code here Your html code here Your html code here Your html code here Your html code here Your html code here Your html code here ",
        imagePreview: "/img/dummy-01.png"
      }
    }
  }),
  components: {
    InstructorModal,
    CollectionModal,
    ContentModal
  }
};
</script>

<style>
.video-preview {
  width: 276px;
  height: 108px;
  border-radius: 3px;
  background-color: #f2f2f2;
  cursor: pointer;
}

.discount-percent {
  border-radius: 3px;
  border: solid 1px #b6b6b6;
  background-color: #f7f8fa;
  font-size: 13px;
  color: #494949;
  margin-bottom: 0.5rem;
  padding: 4px 10px;
  cursor: pointer;
}

.discount-percent:hover,
.discount-percent--active {
  border: solid 1px #3bbaed;
  background-color: #3bbaed;
  color: #fff;
}

.form-tab {
  font-size: 14px;
  font-weight: 500;
  color: #b6b6b6;
  border-left: 5.7px solid transparent;
  cursor: pointer;
  padding: 11px 11px;
  border-bottom: 1px solid #fff;
}

.form-tab--active,
.form-tab:hover {
  background-color: #e3f7ff;
  border-left: 5.7px solid #2085dc;
  color: #2085dc;
}

.instructor-social {
  width: 21px;
  height: 21px;
  background-color: #bababa;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 21px;
  cursor: pointer;
  margin-left: 6px;
}

.instructor-social:hover {
  background-color: #3bbaed;
}

#instructorModal .modal-dialog,
#collectionModal .modal-dialog,
#contentModal .modal-dialog {
  width: 708px;
  max-width: 95%;
}
</style>
