<template>
  <div class="content-modal-container">
    <h4 class="color3 text-center">ADD NEW CONTENT</h4>
    <label class="mt-3">
      Type
    </label>
    <br />
    <b-form-select
      v-model="newContent.type"
      :options="types"
      class="w-auto"
    ></b-form-select>
    <br />

    <label class="mt-4-5">
      Youtube Link
    </label>
    <b-form-input v-model="newContent.youtubeLink"></b-form-input>

    <label class="mt-4-5">
      Duration
    </label>
    <b-form-input
      placeholder="Type Here..."
      v-model="newContent.duration"
    ></b-form-input>

    <p class="lite mt-3 mb-0">
      URL Video: https://securevideo.id/asahdadhfaje.mp4
    </p>

    <input
      type="file"
      name="filecontentvideo"
      id="filecontentvideo"
      class="hidden-input"
    />
    <label for="filecontentvideo" class="file-upload">
      <fa-icon icon="upload" class="my-icon" />
      Upload Video
    </label>

    <div class="justify-end mt-4 mb-3">
      <my-button
        type="outline-blue"
        size="lg"
        class="mr-2"
        @click="$emit('cancel')"
      >
        CANCEL
      </my-button>

      <my-button type="blue" size="lg" @click="handleSave">
        SAVE
      </my-button>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    newContent: {
      name: "Getting started",
      duration: "",
      type: "",
      youtubeLink: "https://youtube.com/ahdaueryd25317?"
    },
    types: [
      {
        text: "Choose Type",
        value: ""
      },
      {
        text: "Video",
        value: "Video"
      },
      {
        text: "PDF",
        value: "PDF"
      }
    ]
  }),
  methods: {
    handleSave() {
      this.$emit("save", this.newContent);
    }
  }
};
</script>

<style>
.content-modal-container {
  padding: 20px 44px;
}

@media screen and (max-width: 768px) {
  .content-modal-container {
    padding: 20px 0;
  }
}
</style>
