export const courseContents = [
  {
    title: "Introduction Part",
    chapters: [
      {
        name: "Getting started",
        duration: "30 min"
      },
      {
        name: "Interactive Lessons 1",
        duration: "30 min"
      }
    ]
  },
  {
    title: "Lesson 1",
    chapters: []
  }
];
